@import url('https://fonts.googleapis.com/css2?family=Edu+TAS+Beginner&family=Pacifico&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  background-image: linear-gradient( 135deg, #97ABFF 10%, #305ace 100%);
  background-attachment: fixed;
  font-family: 'Edu TAS Beginner', cursive;
}

.font-sans,
.title,
.intro > p,
.cursive  {font-family: 'Edu TAS Beginner', cursive;}

h1.title  {font-family:'Pacifico',cursive}
h3.title {font-size:2rem}

.intro .text-xl {font-size:1.5rem}

.card {user-select: none;}

.water-drop, .coin, .filter  {display:inline;height:2rem}
.rating * {display:inline}
.rating span {font-size:12px}
.coin {margin-inline:2px;margin-top:6px}

.pill {font-size:0.65rem}

.loader-spin {
  animation: spin-animation 1s infinite;
  display: inline-block;
  font-size:4rem;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}



#daylit  .bg-current,
#eurlit .bg-current {    background-color: rgba(255,255,255,0);}

footer {margin-bottom:env(safe-area-inset-bottom);}

@media(max-width:680px)
{
  body, html {font-size:14px}
}

@media(max-width:400px)
{
  body, html {font-size:12px}
}
